import React from "react"

 function Dashboard(){

    return (
        <>ok
        
        </>
    )
}


export default Dashboard;